import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SliderIndex from "../components/slider-index"
import ReactModal from 'react-modal'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const hrstyle = {width: 'full', borderTopWidth: '1px!important', marginLeft: 'auto', marginRight: 'auto'};
const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    content: {
        position: "relative",
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        maxWidth: "600px",
        margin: "120px auto 0",
        padding: 0,
        border: 0,
    },
};

const axios = require("axios");
const jQuery = require("jquery");

const AportacionesPage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const modalCloseTimeout = 300;
    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = () => {
        setModalOpen(true);
    };
    const solicitar = () => {
        setModalOpen(false);
    };

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject] = useState("Sitio Fundación Pedro Pérez Garrido - Formulario de Donativos");
    const [message, setMessage] = useState("")
    const [phone, setPhone] = useState("")
    const [sitio] = useState("Fundación Pedro Pérez Garrido");

    const send = async function (e) {
        e.preventDefault()

        jQuery("#btn-cont").css('pointer-events', 'none')
        jQuery("#btn-cont").html('Enviando...')

        if (!executeRecaptcha) {
            return
        }

        if (email.length === 0 || email.trim().length === 0) {
            return
        } else if (message.length === 0 || message.trim().length === 0) {
            return
        } else if (subject.length === 0 || subject.trim().length === 0) {
            return
        } else if (name.length === 0 || name.trim().length === 0) {
            return
        }
        const result = await executeRecaptcha("contacto")
        const headers = {
            "Content-Type": "application/json"
        }
        axios
            .post(
                "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
                {
                    name,
                    email,
                    message,
                    subject,
                    phone,
                    sitio,
                    token: result
                },
                {
                    headers: headers
                }
            )
            .then(res => {
                jQuery("#donativo-form").slideUp("slow").hide();
                jQuery("#result").append(
                    '<hr class="mt-4"><div class="success text-center"><h4>¡Correo electrónico enviado con éxito!</h4><br><p>Gracias por usar nuestro formulario de contacto</p></div><hr>'
                )
            })
    }

    return (
        <Layout>
            <SEO title="Home"/>
            {/* ============================================================== */}
            {/* Feature 3  */}
            {/* ============================================================== */}
            <div className=" bg-white spacer feature3 mt-5">
                <div className="container">
                    {/* Row  */}
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="title">Aportaciones</h3>
                            <p className="subtitle">La Fundación Pedro Pérez Garrido es fortalecida por su donación
                            financiera o material, para seguir trabajando a favor de un desarrollo económico más equitativo
                            y sostenible para la Zona Sur del Estado de Quintana Roo, así como continuar apoyando a los segmentos
                            de la población más necesitada.
                            </p>
                            <p className="mt-20">Al donar a la Fundación Pedro Pérez Garrido, usted apoya a una organización caritativa
                            que se compromete a los más altos estándares de integridad. Sus donativos serán usados al 100% para el
                            propósito que fueron dados.
                            </p>
                            <p className="mt-20">La Fundación Pedro Pérez Garrido lleva a cabo auditorías de manera regular para asegurar
                            que sus programas sean adecuadamente operados y los fondos desembolsados y aplicados apropiadamente, de
                            acuerdo con lineamientos y pautas claras.
                            </p>

                            <p className="mt-20 mb-20">
                                La Fundación acepta donaciones por depósitos bancarios, Paypal y en especie, en función de las necesidades
                                materiales de sus programas sociales activos.
                            </p>
                            <h6>Atrévase a marcar la diferencia en la lucha por dignificar al sur</h6>
                            <div className="card bg-blue">
                            </div>
                        </div>
                    </div>
                    {/* Row  */}


                    <div className="row mt-40">
                        <div className="col-lg-12 col-md-12 text-center">
                            <h3>Mi donativo</h3>
                            <p>Todos los donativos son deducibles de impuestos.</p>
                        </div>

                        <div className="col-lg-4 col-md-4 column-322926-0">
                            <div className="image-control text-center">
                                <picture>
                                    <source media="(max-width: 480px)"
                                            srcSet="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Azul.jpg"/>
                                    <source media="(max-width: 768px)"
                                            srcSet="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Azul.jpg"/>
                                    <img alt="" title=""
                                         src="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Azul.jpg"
                                         className="img-responsive  "
                                         style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                </picture>
                            </div>

                            <hr className="separator-control solid" style={hrstyle}/>
                            <div className="image-control text-center">
                                <a href="#" target="_blank">
                                    <picture>
                                        <source media="(max-width: 480px)"
                                                srcSet="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/xs_tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"/>
                                        <source media="(max-width: 768px)"
                                                srcSet="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/sm_tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"/>
                                        <img alt="" title=""
                                             src="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"
                                             className="content-img  "
                                             style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                    </picture>
                                </a>
                            </div>

                            <hr className="separator-control solid" style={hrstyle}/>
                            <h2 className="subtitle-control text-center">$150 MENSUAL</h2>
                            <div className="text-control lead">

                                <p style={{textAlign: 'center'}}>&nbsp;</p>
                                </div>
                        </div>
                        <div className="col-lg-4 col-md-4 column-322926-1">
                            <div className="image-control text-center">
                                <picture>
                                    <source media="(max-width: 480px)"
                                            srcSet="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Plata2.jpg"/>
                                    <source media="(max-width: 768px)"
                                            srcSet="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Plata2.jpg"/>
                                    <img alt="" title=""
                                         src="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Plata2.jpg"
                                         className="img-responsive  "
                                         style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                </picture>
                            </div>

                            <hr className="separator-control solid" style={hrstyle}/>
                            <div className="image-control text-center">
                                <a href="#" target="_blank">
                                    <picture>
                                        <source media="(max-width: 480px)"
                                                srcSet="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/xs_tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"/>
                                        <source media="(max-width: 768px)"
                                                srcSet="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/sm_tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"/>
                                        <img alt="" title=""
                                             src="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"
                                             className="content-img  "
                                             style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                    </picture>
                                </a>
                            </div>

                            <hr className="separator-control solid" style={hrstyle}/>
                            <h2 className="subtitle-control text-center">$300 MENSUAL</h2>
                            <div className="text-control lead">

                                <p style={{textAlign: 'center'}}>&nbsp;</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 column-322926-2">
                            <div className="image-control text-center">
                                <picture>
                                    <source media="(max-width: 480px)"
                                            srcSet="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Oro2.jpg"/>
                                    <source media="(max-width: 768px)"
                                            srcSet="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Oro2.jpg"/>
                                    <img alt="" title=""
                                         src="https://investorcloud.s3.amazonaws.com/irstrat/demos/sites/fundacion/Oro2.jpg"
                                         className="img-responsive  "
                                         style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                </picture>
                            </div>

                            <hr className="separator-control solid" style={hrstyle}/>
                            <div className="image-control text-center">
                                <a href="#" target="_blank">
                                    <picture>
                                        <source media="(max-width: 480px)"
                                                srcSet="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/xs_tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"/>
                                        <source media="(max-width: 768px)"
                                                srcSet="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/sm_tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"/>
                                        <img alt="" title=""
                                             src="https://cdn.easy-rez.com/production/hotels/89196b89d2f4b6e4a27425eac1049af5/public/.crops/tmb_btn_donatecc_lg.gif_1920x800_0-0.gif"
                                             className="content-img  "
                                             style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                    </picture>
                                </a>
                            </div>

                            <hr className="separator-control solid" style={hrstyle}/>
                            <h2 className="subtitle-control text-center">$500 MENSUAL</h2>
                            <div className="text-control lead">

                                <p style={{textAlign: 'center'}}>&nbsp;</p>
                                </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 text-center btn btn-info">
                            <h3 className="text-white mb-0" onClick={openModal}> DONATIVOS EN ESPECIE</h3>
                        </div>
                    </div>
                </div>
            </div>

            <ReactModal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                // contentLabel="Example Modal In Gatsby"
                style={modalStyles}
            >
                <div className="close p-2" onClick={closeModal}><i className="sl-icon-close"></i></div>
                <div className="p-5">
                    <p>Tras enviar este formulario, uno de los colaboradores de la Fundación se pondrá en contacto con
                        usted
                        tan pronto sea posible dentro de horarios laborales estándar en México.</p>
                    <div id="result"></div>
                    <form onSubmit={event => send(event)} method="post" id="donativo-form">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre completo:</label><br/>
                                <input name="name" required className="form-control mb-2"
                                       onChange={event => setName(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label>Correo electrónico:</label><br/>
                                <input type="email" className="form-control mb-2" name="email"
                                       onChange={event => setEmail(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label>Teléfono:</label><br/>
                                <input type="tel" className="form-control mb-2" name="phone"
                                       onChange={event => setPhone(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label>Mensaje:</label><br/>
                                <textarea className="form-control" rows={1} name="mensaje" defaultValue={""}
                                          onChange={event => setMessage(event.target.value)}/>
                            </div>
                            <br/>
                            <button className="mt-3 btn btn-light mr-2" onClick={closeModal}>Cancelar</button>
                            <button id="btn-cont" className="mt-3 btn btn-info" type="submit">Enviar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        </Layout>
    )
}

export default AportacionesPage
